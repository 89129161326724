import * as React from 'react';
import Banner from '../../Components/Banner/Banner';
import Layout from '../../Components/Layout/Layout';

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Banner title='Page Not Found' />
    </Layout>
  );
};

export default NotFoundPage;
